import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-grid-system";
import FluidContent from "./fluid-content";

import paragonLogoWhite from "../images/paragon-logo-white.png";
import FooterLinks from "./footer-links";

function Footer() {
  return (
    <FluidContent className="site-footer">
      <footer>
        <Container>
          <Row className="site-footer__top">
            <Col lg={6} className="site-footer__left site-footer__col">
              <Link to="/" className="site-footer__logo tab-hidden">
                <img src={paragonLogoWhite} alt="Paragon Therapeutics Logo" />
              </Link>
              <Row className="site-footer__bottom">
                <Col className="site-footer__sm" style={{ paddingRight: 0 }}>
                  <p>&copy; Copyright 2024 Paragon Therapeutics, Inc.</p>
                </Col>
                <Col className="site-footer__sm">
                  <ul>
                    <FooterLinks />
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="site-footer__right site-footer__col">
              <Row>
                <Col xl={6} lg={12} md={6}>
                  <div className="site-footer__item">
                    <h5>Contact</h5>
                    <a
                      href="mailto:info@paragontherapeutics.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      info@paragontherapeutics.com
                    </a>
                  </div>
                  <div className="site-footer__item">
                    <h5>Join</h5>
                    <a
                      href="mailto:careers@paragontherapeutics.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      careers@paragontherapeutics.com
                    </a>
                  </div>
                </Col>
                <Col xl={6} lg={12} md={6}>
                  <p>
                    221 Crescent Street <br /> Building 23, Suite 105 <br /> Waltham, MA 02453
                  </p>
                  <a
                    href="https://www.linkedin.com/company/paragon-therapeutics-inc/jobs/"
                    className="link-border"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </Col>
              </Row>
            </Col>
            <Link to="/" className="site-footer__logo mobile-show">
              <img src={paragonLogoWhite} alt="Paragon Therapeutics Logo" />
            </Link>
          </Row>
        </Container>
      </footer>
    </FluidContent>
  );
}

export default Footer;
